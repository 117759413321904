import React, { useState, useEffect, useContext, useMemo } from "react";
import { Switch, Tooltip, Table, Button, InputNumber, Input, message } from "antd";
import { useMenuDisplayManagerActions } from "@iso/actions/menuDisplayManager.actions.js";
import Page from "component/Page/index";
import { CloseOutlined, HolderOutlined } from "@ant-design/icons";
import { Select, Form } from "antd";
import { CSS } from '@dnd-kit/utilities';
import { AppHelper } from 'helpers/helper.js';
import { IMG_NONE } from 'utils/constant.js';
import {
   arrayMove,
   SortableContext,
   useSortable,
   verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useStoreManagerActions } from "@iso/actions/storeManager.actions";
import { useMenuItemManagerActions } from "@iso/actions/menuItemManager.actions";
import MenuItem from "antd/lib/menu/MenuItem";
import menuDisplayByodApi from "api/menuApi";
import menuItemDisplayApi from "api/menuItemDisplayApi";

const { Option } = Select;

const RowContext = React.createContext({});
const DragHandle = () => {
   const { setActivatorNodeRef, listeners } = useContext(RowContext);
   return (
      <Button
         type="text"
         size="small"
         icon={<HolderOutlined />}
         style={{
            cursor: 'move',
         }}
         ref={setActivatorNodeRef}
         {...listeners}
      />
   );
};

const Row = (props) => {
   const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
   } = useSortable({
      id: props['data-row-key'],
   });
   const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
         ? {
            position: 'relative',
            zIndex: 9999,
         }
         : {}),
   };
   const contextValue = useMemo(
      () => ({
         setActivatorNodeRef,
         listeners,
      }),
      [setActivatorNodeRef, listeners],
   );
   return (
      <RowContext.Provider value={contextValue}>
         <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
   );
};


const MenuStoreChannel = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [mainMenuDisplay, setMainMenuDisplay] = useState({
      search: null,
      data: [],
   });
   const [menuDisplay, setMenuDisplay] = useState([]);
   const [menuItem, setMenuItem] = useState([]);
   const [activeMenu, setActiveMenu] = useState(null);

   const menuDisplayManagerActions = useMenuDisplayManagerActions();
   const storeManagerActions = useStoreManagerActions();
   const menuItemManagerActions = useMenuItemManagerActions();

   const [form] = Form.useForm();
   const [listStoreChannel, setListStoreChannel] = useState([]);
   const [selectedStoreChannel, setSelectedStoreChannel] = useState(null);
   const [selectedMainMenuDisplay, setSelectedMainMenuDisplay] = useState(null);

   const [menuItemNoneStore, setMenuItemNoneStore] = useState([]);

   const getMenuItemNoneStore = async () => {
      setIsLoading(true);
      try {
         const dataGet = await menuDisplayManagerActions.getMenuItemsNoneStore();
         if (dataGet) {
            setMenuItemNoneStore(dataGet);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      getMenuItemNoneStore();
   }, []);

   const handleSelectStoreChannel = (value) => {
      const storechannel = listStoreChannel.find(l => l.id === value)
      setSelectedStoreChannel(storechannel);
   }

   const getListStoreChannel = async () => {
      if (selectedStoreChannel) return;
      setIsLoading(true);
      const res = await storeManagerActions.getStoreChannelList();
      if (res && res.length > 0) {
         setListStoreChannel(res);
      }
      setIsLoading(false);
   }

   const updateIsActive = (isChecked, record) => {
      if (!menuItem.length) return;
      setIsLoading(true);
      try {
         menuItemManagerActions.editMenuItem(record.id, { is_active: isChecked })
         const arr = menuItem.map(item => {
            if (item.id === record.id) return { ...item, is_active: isChecked }
            return item
         })
         setMenuItem(arr)
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const menuItemsColumns = [
      // {
      //    title: "Sort",
      //    key: 'sort',
      //    align: 'center',
      //    width: 80,
      //    render: () => <DragHandle />,
      // },

      {
         title: "Item Name",
         dataIndex: "productItems",
         key: "name",
         render: (record) => record.name,
      },
      {
         title: "Price",
         dataIndex: ["productItems", "base_price"],
         key: "base_price",
         align: "center",
         editable: true,
         width: 250,
      },
      {
         title: "Tax",
         dataIndex: ["productItems", "tax_percentage"],
         key: "tax_percentage",
         align: "center",
         editable: true,
         width: 150,
      },
      {
         title: 'Status',
         dataIndex: 'is_active',
         width: 250,
         render: (_, record) => {
            return <>
               <Switch checked={record.is_active} onChange={(e) => updateIsActive(e, record)} />
               {record?.is_new_data_added &&
                  <Button
                     onlyIcon
                     type="primary"
                     size="small"
                     style={{
                        display: "flex !important",
                        alignItems: "center",
                        margin: "0 16px",
                     }}
                     onClick={() => {
                        const menuItemFilter = menuItem.filter(item => item.id !== record.id);
                        setMenuItem(menuItemFilter);
                     }}
                     icon={<CloseOutlined />}
                  >
                  </Button>}
            </>
         },
      },

   ];


   const EditableCell = ({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
   }) => {
      const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
      return (
         <td {...restProps}>
            {editing ? (
               <Form.Item
                  name={dataIndex}
                  style={{
                     margin: 0,
                  }}
                  rules={[
                     {
                        required: true,
                        message: `Please Input ${title}!`,
                     },
                  ]}
               >
                  {inputNode}
               </Form.Item>
            ) : (
               children
            )}
         </td>
      );
   };

   const getMenuDisplay = async () => {
      if (!selectedStoreChannel || !selectedStoreChannel.store_id) return;
      setIsLoading(true);
      try {
         const dataGet = await menuDisplayManagerActions.getMainMenuDisplay(
            { store_code: selectedStoreChannel.store_id }
         );
         if (dataGet) {
            setMainMenuDisplay({ ...mainMenuDisplay, data: dataGet });
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      getMenuDisplay();
      getListStoreChannel()
   }, [selectedStoreChannel]);

   const handleMainMenuDisplay = async (id) => {
      if (!selectedStoreChannel || !selectedStoreChannel.store_id) return;
      setSelectedMainMenuDisplay(mainMenuDisplay?.data?.find(item => item.id === id) || null);
      const query = { store_code: selectedStoreChannel.store_id, parent_id: id }
      const dataGet = await menuDisplayManagerActions.getMenuDisplayByParent(query);
      if (dataGet?.length) {
         const menuItem = dataGet[0]?.menuItem;
         setMenuItem(menuItem);
         setActiveMenu(dataGet[0]?.id);
      }
      setMenuDisplay(dataGet);
   };

   const handleGetMenuItem = async (id) => {
      const menuItems = menuDisplay?.find(item => item.id === id)?.menuItem;
      setMenuItem(menuItems);
      setActiveMenu(id);
   }

   const onSearch = (val, key) => {
      if (key === 'menuDisplays') {
         setMainMenuDisplay({ ...mainMenuDisplay, search: val });
      }
   };

   const onDragEnd = ({ active, over }) => {
      if (active.id !== over?.id) {
         setMenuItem((prevState) => {
            const activeIndex = prevState.findIndex((record) => record.id === active?.id);
            const overIndex = prevState.findIndex((record) => record.id === over?.id);
            return arrayMove(prevState, activeIndex, overIndex);
         });
      }
   };

   function format_number(num = 0) {
      return num?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
   }

   const handleSelectMenuItemNoneStore = async (value) => {
      console.log(value);
      console.log(menuItem);
      let findItem = menuItemNoneStore.find(item => item.id === value);
      if (findItem) {
         findItem = { ...findItem, is_new_data_added: true }
         setMenuItem([findItem, ...menuItem,]);
      }
   }

   const handleSubmit = async () => {
      let body = [];
      console.log(menuItem)
      menuItem.forEach(item => {
         body.push({
            menu_item_id: item.id,
            menu_display_id: selectedMainMenuDisplay.id,
            store_id: selectedStoreChannel.store_id,
            is_active: item.is_active
         })
      })
      const res = await menuItemDisplayApi.postMenuItemDisplay(body);
      if (res && res.status) {
         message.success('Submit success');
      }
   }

   return (
      <Page title="Menu Store Channel">
         <div className="flex gap-6 mt-5 relative z-0">
            <div className="flex flex-col gap-4" style={{ width: '15%' }}>
               <Select
                  className="w-full mb-4"
                  placeholder="Choose store channel"
                  showSearch
                  optionFilterProp="children"
                  onChange={handleSelectStoreChannel}
                  value={selectedStoreChannel?.id}
               >
                  {listStoreChannel && listStoreChannel.length &&
                     listStoreChannel.map((item) => (
                        <Option value={item?.id} key={item.id}>
                           {item?.stores?.full_name}
                        </Option>
                     ))}
               </Select>
               <Select
                  className="w-full"
                  placeholder="Choose menu display"
                  showSearch
                  optionFilterProp="children"
                  dropdownStyle={{ zIndex: "999 !important" }}
                  onChange={(value) => handleMainMenuDisplay(value)}
                  onSearch={e => onSearch(e, 'mainMenuDisplays')}
               >
                  {mainMenuDisplay && mainMenuDisplay.data.length &&
                     mainMenuDisplay.data.map((item) => (
                        <Option value={item?.id} key={item.id}>
                           {item?.displays.name}
                        </Option>
                     ))}
               </Select>


               <div className="bg-white-0 rounded-md p-4">
                  <p className="flex flex-wrap gap-2">Menu display ID: <span>{selectedMainMenuDisplay ? selectedMainMenuDisplay?.displays?.id : '-'}</span></p>
                  <p className="flex flex-wrap gap-2">Menu display name: <span>{selectedMainMenuDisplay ? selectedMainMenuDisplay?.displays?.name : '-'}</span></p>
                  <p className="flex flex-wrap gap-2">Store code: <span>{selectedStoreChannel ? selectedStoreChannel?.store_id : '-'}</span></p>
                  <p className="flex flex-wrap gap-2">Store name:  <span>{selectedStoreChannel ? selectedStoreChannel?.stores?.full_name : '-'}</span></p>
                  <p className="mb-0">Channel: <span>{selectedStoreChannel ? selectedStoreChannel?.storeSaleChannels?.name : '-'}</span></p>
               </div>
            </div>

            <Form form={form} component={false}>

               <div className="flex-1">
                  <div className="mb-4">
                     <Select
                        className="w-full"
                        placeholder="Add more menu item none store"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        autoClearSearchValue
                        dropdownStyle={{ zIndex: "999 !important" }}
                        onChange={handleSelectMenuItemNoneStore}
                     // onSearch={e => onSearch(e, 'mainMenuDisplays')}
                     >
                        {menuItemNoneStore && menuItemNoneStore?.length > 0 &&
                           menuItemNoneStore?.filter(x => !menuItem.find(m => m.id === x.id))?.map((item) => (
                              <Option value={item?.id} key={item.id}
                              >
                                 {item?.productItems.name}
                              </Option>
                           ))}
                     </Select>
                  </div>
                  <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                     <SortableContext items={menuItem.map((i) => i.id)} strategy={verticalListSortingStrategy}>
                        <Table
                           rowKey="id"
                           components={{
                              body: {
                                 row: Row,
                                 cell: EditableCell,
                              },
                           }}
                           loading={isLoading}
                           columns={menuItemsColumns}
                           dataSource={menuItem}
                           pagination={false}
                        />
                     </SortableContext>
                  </DndContext>
               </div>
               <div className="w-1/4">
                  <Button type="primary" className="mb-4" onClick={handleSubmit}>Submit</Button>
                  <div className="wrapper-preview rounded-lg">
                     <div className="menu-display">
                        {menuDisplay?.map((item, index) => (
                           <div key={index} className={`mb-8 ${activeMenu == item.id ? 'active' : ""}`} onClick={() => handleGetMenuItem(item.id)}>
                              <img src={item?.displays?.image_url || IMG_NONE} alt={item?.displays.name} />
                              <h4 className={`${activeMenu == item.id ? 'font-bold' : ""}`}>{item?.displays.name}</h4>
                           </div>
                        ))}
                     </div>
                     <div className="menu-item">
                        <div className="grid grid-cols-12 gap-4">
                           {menuItem?.map((item) => (
                              <div key={item?.id} className="col-span-6">
                                 <img src={item?.productItems?.image_url || IMG_NONE} alt={item?.productItems?.name} />
                                 <Tooltip title={item?.productItems?.name}><h4>{AppHelper.shortenString(item?.productItems?.name, 15)}</h4></Tooltip>
                                 <div className="money">{format_number(parseFloat(item?.productItems?.base_price))} vnđ</div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </Form>
         </div>
      </Page>
   );
};

export default MenuStoreChannel;
